import { defineStore } from "pinia"

export const useStore = defineStore("main", {
  state: () => ({
    initialized: false,
    sampleStoreVariable: "Sample store value",
    sampleTextIsVisible: true,
  }),

  actions: {
    initializeApp() {
      this.initialized = true
      console.info("App initialized!", {
        MODE: import.meta.env.MODE,
        REVISION: import.meta.env.VITE_GITHUB_SHA,
      })
    },
    toggleSampleText() {
      this.sampleTextIsVisible = !this.sampleTextIsVisible
    },
    resetSampleTextIsVisible() {
      this.sampleTextIsVisible = true
    },
  },

  getters: {
    isInitialized: (state) => {
      return !state.initialized
    },
  },
})
