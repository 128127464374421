<template>
  <Default>
    <template #actions>
      <RouterLink class="button" :to="{ name: 'Index' }">
        {{ t("shared.back") }}
      </RouterLink>
    </template>

    <template #title>
      {{ t("general.stations") }}
    </template>

    <div class="grid grid-cols-3 gap-8">
      <button
        v-for="station in stations"
        :key="station.index"
        class="button text-center text-2xl !py-4"
        @click="selectStation(station.index)"
      >
        {{ t(`stations.content.${station.index}.title`) }}
      </button>
    </div>
  </Default>
</template>

<script setup lang="ts">
import stations from "@/data/stations"
import { useI18n } from "vue-i18n"
import { post } from "@/api/main"
import { Action } from "@/api/types"
import { InvalidStationIndexError } from "@/errors/error"

const { t } = useI18n()
const router = useRouter()

const selectStation = async (index: string) => {
  type StationMap = {
    [key: string]: Action
  }

  const stationMap: StationMap = {
    "1": "select_01",
    "2": "select_02",
    "3": "select_03",
    "4": "select_04",
    "5": "select_05",
    "6": "select_06",
    "7": "select_07",
    "8": "select_08",
  }

  try {
    if (Object.keys(stationMap).includes(index)) {
      await post(stationMap[index])
      router.push({ name: `Station${index}` })
    } else {
      throw new InvalidStationIndexError(index)
    }
  } catch {
    throw new InvalidStationIndexError(index)
  }
}
</script>

<style lang="postcss" scoped></style>
