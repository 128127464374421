<template>
  <router-view v-slot="{ Component, route }">
    <Transition name="router-fade" mode="out-in">
      <div :key="route.name" class="h-screen">
        <component :is="Component"></component>
      </div>
    </Transition>
  </router-view>
</template>

<style lang="postcss">
.router-fade-enter-active {
  transition: all 0.3s ease-out;
}
.router-fade-enter-from,
.router-fade-leave-to {
  opacity: 0;
}
</style>
