<template>
  <Default :show-locale-switcher="false">
    <div class="mt-8 flex w-full justify-around">
      <div class="flex gap-x-16">
        <button
          class="w-32 h-32 text-5xl flex justify-center items-center"
          @click="chooseLocale('en')"
        >
          EN
        </button>
        <button
          class="w-32 h-32 text-5xl flex justify-center items-center"
          @click="chooseLocale('de')"
        >
          DE
        </button>
      </div>
    </div>
  </Default>
</template>

<script setup lang="ts">
import { post } from "@/api/main"
import { Action } from "@/api/types"
import { InvalidLangIndexError } from "@/errors/error"

const router = useRouter()

const chooseLocale = async (index: string) => {
  type StationMap = {
    [key: string]: Action
  }

  const langMap: StationMap = {
    en: "lang_01",
    de: "lang_02",
  }

  try {
    if (Object.keys(langMap).includes(index)) {
      await post(langMap[index])
      router.push({ name: "StationsIndex", params: { locale: index } })
    } else {
      throw new InvalidLangIndexError(index)
    }
  } catch {
    throw new InvalidLangIndexError(index)
  }
}
</script>

<style lang="postcss" scoped></style>
