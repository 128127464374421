import { defineAsyncComponent } from "vue"
import { createRouter, createWebHistory } from "vue-router"

import Index from "@/pages/Index.vue"
import Settings from "@/pages/Settings.vue"
import StationsIndex from "@/pages/Stations/Index.vue"
import i18n from "./i18n"
import stations from "@/data/stations"
import { Station } from "./types"

const settings: object[] = []

if (["development", "test", "staging"].includes(import.meta.env.MODE)) {
  settings.push({
    path: "/settings",
    name: "Settings",
    component: Settings,
    meta: {
      title: "Settings",
    },
  })
}

const routes = [
  {
    path: "/",
    name: "Index",
    component: Index,
    meta: {
      title: "Index",
    },
  },
  ...settings,
  {
    path: "/:locale(de|en)",
    children: [
      {
        path: "stations",
        children: [
          {
            path: "",
            name: "StationsIndex",
            meta: {
              title: "StationsIndex",
            },
            component: StationsIndex,
          },
          ...stations.map((station: Station) => ({
            path: station.index,
            name: `Station${station.index}`,
            component: () =>
              import(`@/pages/Stations/Station${station.index}.vue`),
            meta: {
              title: "Station",
            },
          })),
        ],
      },
    ],
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

router.beforeEach(async (to, _from) => {
  if (["de", "en"].includes(to?.params?.locale as string)) {
    i18n.global.locale.value = to?.params?.locale
  }
})

export default router
