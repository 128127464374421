import { createHead } from "@vueuse/head"
import { createPinia } from "pinia"
import { createApp } from "vue"

import { appsignal } from "./appsignal"
import { errorHandler } from "@appsignal/vue"

import i18n from "./i18n"

import App from "./App.vue"

import "./assets/styles/global.postcss"

import router from "./router"

const head = createHead()
const app = createApp(App)

app.config.errorHandler = (err, instance, info) => {
  console.error(err, instance, info)
  if (import.meta.env.MODE === "development") {
    alert(err)
  }
  errorHandler(appsignal, app)(err, instance, info)
}

app.use(createPinia())
app.use(router)
app.use(head)
app.use(i18n)

const store = useStore()
store.initializeApp()

app.mount("#app")
