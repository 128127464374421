import { ValidationError } from "yup"
import ky from "ky"
import { v4 as uuidv4 } from "uuid"
import pTimeout from "p-timeout"

import { successSchema, Success, Action, actions } from "@/api/types"
import { InvalidActionError, ActionError } from "@/errors/error"

const MOCK_API_BASE = () => {
  if (["development", "test", "staging"].includes(import.meta.env.MODE)) {
    return localStorage?.getItem("MOCK_API_BASE") !== "" &&
      localStorage?.getItem("MOCK_API_BASE")?.startsWith("http")
      ? localStorage?.getItem("MOCK_API_BASE")
      : import.meta.env.VITE_API_BASE
  }
  return undefined
}

const API_BASE =
  MOCK_API_BASE() || import.meta.env.VITE_API_BASE || "NO_API_BASE_SET"

const waitFor = async (
  res: () => Promise<void>,
  timeout = 5000
): Promise<void> =>
  await pTimeout(res(), {
    milliseconds: timeout,
  })

const genericOptions = {
  prefixUrl: API_BASE,
}

const postLog = async (
  requestId: string,
  message: string,
  severity: string = "info",
  group: string = "default"
) => {
  if (import.meta.env.VITE_APPSIGNAL_LOG_API_KEY?.startsWith("ls-")) {
    try {
      const timestamp = new Date().toISOString()
      const json = {
        timestamp,
        severity,
        message: `[${requestId}] ${message}`,
        group,
        hostname: "frontend",
      }
      const endpoint =
        "https://appsignal-endpoint.net/logs?api_key=" +
        import.meta.env.VITE_APPSIGNAL_LOG_API_KEY

      await ky
        .post(endpoint, {
          mode: "no-cors",
          headers: {
            "Content-Type": "application/json",
          },
          json,
        })
        .json()
    } catch {
      // do nothing
    }
  }
}

// export const genericGetRequest = async (
//   endpoint: string,
//   options: object = {}
// ) => await ky.get(endpoint, { ...genericOptions, ...options }).json()

// export const get = {
//   foo: async (): Promise<Foo> =>
//     await fooSchema.validate(await genericGetRequest(`foo`)),
// }

const genericPostRequest = async (
  endpoint: string,
  json: object = {},
  options: object = {}
) =>
  await waitFor(
    () => ky.post(endpoint, { ...genericOptions, json, ...options }).json(),
    5000
  )

const performAndValidateAction = async (requestId: string, action: Action) => {
  try {
    const response = await genericPostRequest(`action`, { action }) // perform the request, we await the promise with the response
    try {
      await successSchema.validate(response)
      postLog(requestId, `Successfully finished action '${action}'`)
    } catch (validationError) {
      if (validationError instanceof ValidationError) {
        postLog(
          requestId,
          `Schema validation (successSchema) failed while trying to perform action '${action}'`,
          "error"
        )
      } else {
        throw validationError
      }
    }
    return response
  } catch (error) {
    postLog(
      requestId,
      `Something else went wrong while trying to perform action '${action}'`,
      "error"
    )
    throw error
  }
}

const isAction = (maybeAction: unknown): maybeAction is Action =>
  typeof maybeAction === "string" && actions.includes(maybeAction as Action)

const parseAction = (actionString: string): Action => {
  if (isAction(actionString)) {
    return actionString as Action
  }
  throw new InvalidActionError(actionString)
}

export const post = async (action: Action): Promise<Success | void> => {
  const requestId = uuidv4()
  const parsedAction = parseAction(action)
  postLog(requestId, `Starting action '${action}'`)

  try {
    return await performAndValidateAction(requestId, parsedAction)
  } catch {
    throw new ActionError(action)
  }
}
