import { object, bool, InferType } from "yup"

export const successSchema = object({
  success: bool().required().isTrue(),
})

export type Success = InferType<typeof successSchema>

export const actions = [
  "lang_01",
  "lang_02",
  // ---
  "select_01",
  "01_overview",
  "01_sound-minus",
  "01_sound-plus",
  "01_play",
  "01_end",
  "01_next",
  // ---
  "select_02",
  "02_overview",
  "02_sound-minus",
  "02_sound-plus",
  "02_next",
  // ---
  "select_03",
  "03_overview",
  "03_sound-minus",
  "03_sound-plus",
  "03_play-01",
  "03_start-01",
  "03_start-02",
  "03_start-03",
  "03_end",
  "03_next",
  // ---
  "select_04",
  "04_overview",
  "04_start-01",
  "04_stop-01",
  "04_next",
  // ---
  "select_05",
  "05_overview",
  "05_start-01",
  "05_stop-01",
  "05_next",
  // ---
  "select_06",
  "06_overview",
  "06_sound-minus",
  "06_sound-plus",
  "06_next",
  // ---
  "select_07",
  "07_overview",
  "07_sound-minus",
  "07_sound-plus",
  "07_start-01",
  "07_start-02",
  "07_continue",
  "07_back",
  "07_next",
  // ---
  "select_08",
  "08_overview",
  "08_sound-minus",
  "08_sound-plus",
  "08_start-01",
  "08_end",
] as const

export type Action = (typeof actions)[number]
