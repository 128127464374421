import Appsignal from "@appsignal/javascript"
import { plugin as pluginNetwork } from "@appsignal/plugin-breadcrumbs-network"
import { plugin as pluginPath } from "@appsignal/plugin-path-decorator"

export const appsignal = new Appsignal({
  key: import.meta.env.VITE_APPSIGNAL_KEY,
  revision: import.meta.env.VITE_GITHUB_SHA,
})
appsignal.use(pluginNetwork({ fetchEnabled: true, xhrEnabled: true }))
appsignal.use(pluginPath({}))
