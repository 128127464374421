import { AppError } from "./app_error"

export class ActionError extends AppError {
  constructor(action: string) {
    super(`Action failed: ${action}`)
    this.name += "ActionError"
  }
}

export class InvalidActionError extends AppError {
  constructor(action: string) {
    super(`Invalid action: ${action}`)
    this.name += "InvalidActionError"
  }
}

export class InvalidStationIndexError extends AppError {
  constructor(index: string) {
    super(`Invalid station index: ${index}`)
    this.name += "InvalidStationIndexError"
  }
}

export class InvalidLangIndexError extends AppError {
  constructor(index: string) {
    super(`Invalid lang index: ${index}`)
    this.name += "InvalidLangIndexError"
  }
}
